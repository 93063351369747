import React, {useEffect, useMemo, useState, useRef, useCallback} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {HitsProps, useHits, usePagination} from 'react-instantsearch-hooks-web'
import {
  useBasketStore,
  useGlobalStore,
  useProductStore,
  useOrderStore,
  useCustomerStore
} from '../../../store/hooks/useStore'
import {Box, Flex, Button, Stack, Text, useDisclosure} from '../../../vanilla'
import {Link} from '../../link'
import {ArrowRightIcon, ChevronRightIcon, HeartFilledIcon, HeartIcon} from '../../icons'
import {useItemPrice} from '../util/useItemPrice'
import {ReviewStars} from '../../review-stars'
import {PDPBadgeAsset} from '../../cms/product-badges'
import {Hit, ProductModel} from '../../../store/ProductStore'
import {
  MultiBuyPromo,
  MultiBuyPromoOfferMessage,
  NearPromo,
} from '../../product-detail/product-promo'
import useWishlistItem from '../../../store/hooks/useWishlistItem'
import {GridCardProps} from '../../cms/GridCard'
import {useAlcoholRestrictions} from '../../../hooks/use-alcohol-restrictions'
import {hitsGrid, mobilePLPBadges} from './styles.css'
import {useLocation} from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import {useAlgoliaAnalytics, useBookDeliveryPopover} from '../../../contexts'
import useEinstein from '../../../store/hooks/useEinstein'
import {Nullable} from '../../../types/utils'
import {useSelectItemTracking} from '../../../analytics/select-item-tracking'
import {Price} from '../../price'
import {Slot} from '../../cms/Slot'
import {SlotContentItem, VariationContent} from '../../../types/cms'
import UpdateCartItemQuantity from '../../update-cart-quantity'
import {transformHit} from '../util/transformHit'
import {ATBButtonPopover} from '../../add-to-basket-button-popover'
import {useBasketItem} from '../../../store/hooks/useBasketItem'
import {LearnMoreAlcoholRestrictionModal} from '../../alcohol-restriction-modal'
import {AlcoholRestrictionOverlay} from '../../alcohol-restriction-overlay'
import {EVENT_NAMES_MAP} from '../../../analytics/algolia'
import {HITS_PER_PAGE, STAR_RATING_THRESHOLD} from '../../../constants'
import {getShowValues} from '../../../pages/before-you-go/helpers'
import {CART_LOCATION} from '../../../analytics/utils'
import {useAddToCartTracking} from '../../../analytics/click-event-tracking'
import {useSlotExpiration} from '../../../store/hooks/useSlotExpiration'
import LoadingOverlay from '../../loading-overlay'
import {recommenderHit} from '../../recommendations/styles.css'
import {formatProductName, isMobile, getDisabledButtonText} from '../../../utils/utils'
import {promotionModalContent} from '../../product-detail/product-promo/styles.css'
import {useHitsProductBadges} from '../util/useHitsProductBadges'
import {UnavailableProductsOverlay} from '../../unavailable-overlays'
import Separator from '../../separator'
import {ProductImage} from '../../product-detail/product-image'
import {TopRightProductBadgeGroup} from '../../top-right-product-badges/top-right-product-badge-group'
import debounce from 'lodash.debounce'

export type HitsOptions = {
  showBadges?: boolean
  showPromotions?: boolean
  showRating?: boolean
}

export const Hits: React.FC<
  HitsProps<Hit> & {
    inGridPromos?: Record<string, any>
    categoryId?: Nullable<string>
    options?: HitsOptions
    onHitsUpdated?: (hits: Array<Hit>) => void
  }
> = ({
  inGridPromos,
  className,
  categoryId,
  options,
  onHitsUpdated
}) => {
  const {hits} = useHits<Hit>()
  const {selectedStoreId} = useGlobalStore()
  const {storeHitsAsProducts} = useProductStore()
  const {currentRefinement, refine, canRefine, isLastPage} = usePagination()
  const transformedHits = hits?.map((hit) => transformHit(hit)) as Hit[]
  const [isLoading, setIsLoading] = useState(false)
  const prevStoreIdRef = useRef(selectedStoreId)
  const [pageStartQuery, setPageStartQuery] = useState<string | null>(null)

  useEffect(() => {
    if (prevStoreIdRef.current !== selectedStoreId && selectedStoreId !== '0') {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
    prevStoreIdRef.current = selectedStoreId
  }, [selectedStoreId])

  // Fetch product badges for the hits
  useHitsProductBadges(transformedHits)

  useEffect(() => {
    storeHitsAsProducts(transformedHits)
  }, [transformedHits])
  
  useEffect(() => {
    setPageStartQuery(`${HITS_PER_PAGE * currentRefinement}`)
  }, [currentRefinement])

  const modifySlotInGridPromoCmsContent = (
    gridPromoContentData: SlotContentItem,
    gridCardProps: Partial<GridCardProps>,
  ) => {
    let gridVariationsContent: VariationContent[] = []
    if (gridPromoContentData.variations && Array.isArray(gridPromoContentData.variations)) {
      gridVariationsContent = gridPromoContentData.variations.map((variation) => {
        const {contentItems, ...rest} = variation

        return {
          ...rest,
          contentItems: {...(contentItems || {}), ...gridCardProps},
        }
      })
    }

    const defaultContentArr = Array.isArray(gridPromoContentData.default)
      ? gridPromoContentData.default
      : [gridPromoContentData.default]

    const gridDefaultContent = defaultContentArr.map((defaultContentItem) => {
      return {...defaultContentItem!, ...gridCardProps}
    })

    return {
      ...gridPromoContentData,
      variations: gridVariationsContent,
      default: gridDefaultContent,
      isInGridPromo: true,
    }
  }

  const isHitPromoGridCard = (hit: Hit | SlotContentItem): hit is SlotContentItem =>
    hit?.isInGridPromo

  const hitsAndPromos: Array<Hit | SlotContentItem> = useMemo(() => {
    if (!inGridPromos) {
      return transformedHits
    }

    const _hits: Array<Hit | SlotContentItem> = [...transformedHits]

    inGridPromos?.default?.inGridPositions?.forEach(
      (gridPromoContentData: SlotContentItem, index: number) => {
        if (index === 0) {
          _hits.splice(
            2,
            0,
            modifySlotInGridPromoCmsContent(gridPromoContentData, {
              showOnDesktop: false,
              index: gridPromoContentData.contentSpotPosition,
            }),
          )
          _hits.splice(
            4,
            0,
            modifySlotInGridPromoCmsContent(gridPromoContentData, {
              showOnDesktop: true,
              index: gridPromoContentData.contentSpotPosition,
            }),
          )
        } else if (index === 1) {
          _hits.splice(
            12,
            0,
            modifySlotInGridPromoCmsContent(gridPromoContentData, {
              showOnDesktop: false,
              index: gridPromoContentData.contentSpotPosition,
            }),
          )
          _hits.splice(
            16,
            0,
            modifySlotInGridPromoCmsContent(gridPromoContentData, {
              showOnDesktop: true,
              index: gridPromoContentData.contentSpotPosition,
            }),
          )
        }
      },
    )

    return _hits
  }, [transformedHits, inGridPromos])

  const moveToTop = () => {
    const anchor = document.querySelector('#stats-text')
    const coordinates = {top: 0, left: 0}
    if (anchor) {
      coordinates.top = anchor.getBoundingClientRect?.()?.top + window.scrollY
    }

    window.scrollTo({...coordinates, behavior: 'auto'})
  }

  const onNext = () => {
    if (canRefine && !isLastPage) {
      refine(currentRefinement + 1)
      moveToTop()
    }
  }

  let hitItemIndex = 0

  const debouncedUpdate = useCallback(
    debounce((hits) => {
      if (onHitsUpdated && hits.length > 0) {
        onHitsUpdated(hits)
      } 
    }, 1000),
    [onHitsUpdated]
  )

  useEffect(() => {
    debouncedUpdate(transformedHits)
    return () => {
      debouncedUpdate.cancel()
    }
  }, [transformedHits])

  return (
    <Box className={classNames(hitsGrid, className)}>
      {isLoading && <LoadingOverlay isLoading />}
      {hitsAndPromos.map((hit, idx) => {
        if (isHitPromoGridCard(hit)) {
          return <Slot data={hit} key={idx} />
        }

        hitItemIndex++
        return (
          <HitItem
            isPLPTile
            hit={hit}
            key={hit.id}
            idx={idx}
            pageStartQuery={pageStartQuery}
            hitItemIndex={hitItemIndex}
            viewingCategoryId={categoryId}
            {...options}
          />
        )
      })}
      {/* Filler tile */}
      {(inGridPromos && inGridPromos?.default && inGridPromos?.default?.inGridPositions?.length > 0) && (
        <Flex
          cursor="pointer"
          onClick={onNext}
          display={['none', 'flex']}
          direction="column"
          justify="space-between"
          paddingX="12px"
          paddingY="20px"
          height="full"
          width="full"
          backgroundColor="white"
        >
          <Stack spacing="16px">
            <Text
              paddingBottom="16px"
              borderBottom="1px"
              borderColor="gray500"
              variant="heading3"
              color="accent0"
            >
              View More Products
            </Text>
            <Text variant="text4">Even more products at fantastic prices</Text>
          </Stack>
          <ArrowRightIcon
            alignSelf="center"
            color="gray50"
            width="100px"
            style={{height: '118px'}}
          />
          <Button variant="primary" iconRight={<ChevronRightIcon />} width="full">
            Next Page
          </Button>
        </Flex>
      )}
    </Box>
  )
}

interface HitItemProps {
  hit: Hit
  className?: string
  isPLPTile?: boolean
  idx?: number
  hitItemIndex?: number
  einsteinRecoParams?: {
    recommender: string
    recoUUID: string
  }
  viewingCategoryId?: Nullable<string>
  showBadges?: boolean
  showPromotions?: boolean
  showRating?: boolean
  pageStartQuery?: string | null
  showNearPromo?: boolean // need the option to hide them on meal deal pages
}

const HitItemContainer = ({
  hit,
  className,
  isPLPTile,
  idx,
  hitItemIndex,
  einsteinRecoParams,
  viewingCategoryId,
  showBadges,
  showPromotions,
  showRating,
  showNearPromo
}: HitItemProps) => {
  const learnMoreAlcRestrictionModal = useDisclosure()
  const {events} = useAlgoliaAnalytics()
  const {sendViewProduct, sendClickRecommendations, sendClickCategoryProduct} = useEinstein()
  const basketStore = useBasketStore()
  const {pathname, search} = useLocation()
  const {
    setShowCheckoutSummary,
    customSitePreferences: {showMultibuyPriceBadge},
  } = useGlobalStore()
  const {displayPrice, promotionPrice} = useItemPrice(hit as unknown as ProductModel)
  const {handleRemoveWishlistItem, isInWishlist, handleAddWishlistItem} = useWishlistItem(hit.id)
  const {checkIfProductAlcohol, isCurrentSlotAlcoholRestricted} = useAlcoholRestrictions()
  const isAlcohol = checkIfProductAlcohol(hit)
  const disabledProductBecauseOfRestriction = isAlcohol && isCurrentSlotAlcoholRestricted
  // @todo format the algolia data to be consistent across PLP and PDP - happens in price component too
  // @ts-ignore
  const img = hit.image_groups?.[0]?.images?.[0] || hit.imageGroups?.[0]?.images?.[0]
  const {quantity} = useBasketItem(hit.id)
  const [clickedQuantity, setClickedQuantity] = useState(0)
  const {showBookDeliveryPopover} = useBookDeliveryPopover()
  const isBeforeYouGoPage = pathname === '/Iceland-BeforeYouGoPages'
  const {isReservationExpired, handleEditReservationExpired} = useSlotExpiration()
  const {editMode} = useOrderStore()
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  // checks for a text or image badge asset
  const hasBadgeAsset = !!hit.pdpBadgeAsset
  const {getApplicablePromotions} = useCustomerStore()
  const {multiBuyPromo, nearPromo} = getApplicablePromotions(hit.productPromotions)

  useEffect(() => {
    setClickedQuantity(quantity)
  }, [basketStore.count])

  const addToBasket = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (disabledProductBecauseOfRestriction) {
      return
    }

    if (editMode && isReservationExpired()) {
      // If in edit mode and slot has expired send to order history and don't add to basket
      handleEditReservationExpired()
    } else if (!basketStore.canAddItems) {
      showBookDeliveryPopover({
        clickedHitId: hit.id,
        bookDeliveryProps: {
          product: hit,
        },
      })
    } else {
      if (isBeforeYouGoPage) {
        setShowCheckoutSummary(true)
      }
      basketStore.addProductToUpdate({
        product: hit,
        quantity: 1,
        event: EVENT_NAMES_MAP.PLP_PRODUCT_ADD_TO_CART_AFTER_SEARCH,
        queryID: hit.__queryID,
      })
      setClickedQuantity(1)
    }
  }

  const productLink = `/p/${formatProductName(hit?.name)}/${hit.id}.html`

  const sendProductClickEvents = () => {
    /** Einstein Analytics events **/
    if (isPLPTile && viewingCategoryId)
      sendClickCategoryProduct({
        product: {id: hit.id},
        category: {
          id: viewingCategoryId,
        },
      })
    else if (einsteinRecoParams)
      sendClickRecommendations({
        product: {id: hit.id},
        __recoUUID: einsteinRecoParams.recoUUID,
        recommenderName: einsteinRecoParams.recommender,
      })
    else sendViewProduct({product: {id: hit.id}})

    /** Algolia Analytics events  **/
    events?.productListPage?.sendClickEvent({
      queryID: hit.__queryID,
      positions: [hit.__position],
      objectIDs: [hit.objectID],
    })
  }

  const toggleWishlist = async () => {
    if (isInWishlist) {
      handleRemoveWishlistItem()
      return
    }

    await handleAddWishlistItem(locationOfCart, viewingCategoryId, location.search, hitItemIndex)

    /** Algolia Analytics events  **/
    if (hit.__queryID) {
      events?.productListPage?.sendAddToFavouriteAfterSearchEvent({
        queryID: hit.__queryID,
        objectIDs: [hit.objectID],
      })
    } else {
      events?.productListPage?.sendAddToFavouriteEvent({
        objectIDs: [hit.objectID],
      })
    }
  }

  const {showBadge, showPromos, showRatings} = getShowValues(showBadges, showPromotions, showRating)

  const shouldShowRating = showRatings && (hit.productRating || 0) >= STAR_RATING_THRESHOLD
  const showMultiBuyPromoBadge = Boolean(showMultibuyPriceBadge) && !!multiBuyPromo && multiBuyPromo?.multibuyUnitPrice

  const sendSelectItemData = useSelectItemTracking()
  const sendAddToCartData = useAddToCartTracking(basketStore.basket)
  const isMobileFlag = isMobile()

  const getCartLocation = () => {
    if (typeof location != "undefined") {
      if (search) {
        return CART_LOCATION.SEARCH
      }
      if (location.pathname === '/Iceland-BeforeYouGoPages') {
        return CART_LOCATION.CHECKOUT
      }
      if (className === recommenderHit) {
        return CART_LOCATION.CAROUSEL
      }
      if (className === promotionModalContent) {
        return CART_LOCATION.CAROUSEL
      }
      return CART_LOCATION.PLP
    }
  }

  const locationOfCart = getCartLocation()

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      setSearchQuery(params.get('q'))
    }
  })

  return (
    <Box position="relative" className={className}>
      <Button
        aria-label="Add to favourites"
        variant="unstyled"
        position="absolute"
        right="0px"
        top="0px"
        data-test-selector="favourite-icon"
        onClick={toggleWishlist}
      >
        {isInWishlist ? (
          <HeartFilledIcon color="accent0" boxSize="20px" />
        ) : (
          <HeartIcon color={{default: 'black', hover: 'accent0'}} boxSize="20px" />
        )}
      </Button>
      {/* Top Right Badges on mobile */}
        {isMobileFlag && (
          <TopRightProductBadgeGroup
            hit={hit}
            className={mobilePLPBadges}
            top={[15, 55, 95]}
            right={-5}
          />
        )}
      <Flex
        flexDirection={[isPLPTile ? 'row' : 'column', 'column']}
        flexWrap="wrap"
        align={['flex-start', 'center']}
        paddingX="8px"
        paddingY="12px"
        data-test-selector="product-list-item"
        bg="white"
        height="full"
      >
        <Box
          aria-label={hit.name}
          as={Link}
          href={productLink}
          ratio="1"
          width="full"
          style={{
            maxWidth: 160,
            flexBasis: 148,
            ...(disabledProductBecauseOfRestriction && {
              pointerEvents: 'none',
            }),
          }}
          marginTop={['0px', '32px']}
          flexShrink="0"
          position="relative"
          onClickCapture={() => {
            sendProductClickEvents(), sendSelectItemData(hit, viewingCategoryId, hitItemIndex)
          }}
        >
          {disabledProductBecauseOfRestriction && (
            <AlcoholRestrictionOverlay onLearnMoreClick={learnMoreAlcRestrictionModal.onToggle} />
          )}
          {hit.isAddToCartDisabled && hit.disabledStatus && (
            <UnavailableProductsOverlay reasonForDisabledStatus={hit.disabledStatus} />
          )}
          {/* Top Right Badges on desktop */}
          {!isMobileFlag && (
            <TopRightProductBadgeGroup 
              hit={hit}
              top={[15, 55, 95]}
              right={-5}
            />
           )}
          {typeof idx === 'number' && idx <= 5 ? (
            <ProductImage 
              image={img}
              imageAlt={img?.alt}
              isThumbnail={false}
              inDrawer={false}
              display="block"
              width="full"
              height="auto"
              marginTop={['12px', '0px']}
              paddingRight="8px"
              style={{maxHeight: 160, objectFit: 'contain'}}
            />
          ) : (
            <LazyLoad>
              <ProductImage 
                image={img}
                imageAlt={img?.alt}
                isThumbnail={false}
                inDrawer={false}
                display="block"
                width="full"
                height="auto"
                marginTop={['12px', '0px']}
                paddingRight="8px"
                style={{maxHeight: 160, objectFit: 'contain'}}
              />
            </LazyLoad>
          )}
        </Box>

        <Flex
          align={['flex-start', 'center']}
          flexBasis={['100px']}
          flexGrow="1"
          flexShrink={['0', '1', '1']}
          marginTop={['0px', '20px']}
          marginLeft={['12px', '0px']}
          flexDirection={'column'}
          height={['auto', 'full']}
          gap="12px"
          justifyContent="space-between"
        >
          {/* Near promo */}
          {showPromos && nearPromo && showNearPromo !== false ? <NearPromo promotion={nearPromo} /> : null}
          <Text
            as={Link}
            aria-label={hit.name}
            href={productLink}
            variant="text4"
            textAlign={['left', 'center']}
            flex="1"
            height="full"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
            overflow="hidden"
            color={{default: 'gray800', hover: 'gray700'}}
            marginTop={['32px', '0px']}
            marginBottom={['12px', '0px']}
            marginRight={['36px', '0px']}
            lineHeight="short"
            data-test-selector="product-list-item-name"
            onClickCapture={() => {
              sendProductClickEvents(), sendSelectItemData(hit, viewingCategoryId, hitItemIndex)
            }}
          >
            {hit.name}
          </Text>

          <Flex
            flexDirection="column"
            gap="12px"
            alignItems={['flex-start', 'center']}
            justifyContent="space-between"
          >
            {shouldShowRating && hit.productRating ? (
              <ReviewStars rating={hit.productRating} />
            ) : (
              <Box height="20px" display={['none', 'block']} />
            )}
            <Price
              price={displayPrice}
              promotionPrice={promotionPrice}
              priceInfo={hit.priceInfo}
              isPLPTile={isPLPTile}
            />
            {/* Show the separator and multiBuyBadge */}
            {showPromos && showMultiBuyPromoBadge ? (
              <>
                <Separator />
                <MultiBuyPromo promotion={multiBuyPromo} />
              </>
            ) : null}
          </Flex>
          {/* Badges: text or image */}
          {showBadge && hit.pdpBadgeAsset ? <PDPBadgeAsset badgeKey={hit.pdpBadgeAsset} /> : null}
        </Flex>
          {/* Nearness +x message */}
          {showPromos && nearPromo ? (
            <Box paddingTop="12px" marginLeft={["auto", "0px"]} marginTop={['auto', '0px']}>
              <MultiBuyPromoOfferMessage promotion={nearPromo} productId={hit.id} />
            </Box>
          ) : null}
        <Box
          width={[isPLPTile ? 'full' : 'auto', 'auto']}
          display={[isPLPTile ? 'flex' : 'block', 'block']}
          justifyContent="flex-end"
          marginTop={hasBadgeAsset ? ['8px', '16px'] : ['8px', '24px']}
        >
          {hit.isAddToCartDisabled ? (
            <>
              {!disabledProductBecauseOfRestriction ? (
                <>
                  <Box display={['none', 'block']}>
                    <Button disabled variant="secondary" marginTop="16px" style={{width: 150}}>
                      {getDisabledButtonText(hit.disabledStatus)}
                    </Button>
                  </Box>
                  <Box display={['block', 'none']}>
                    <Button disabled variant="secondary" marginTop="12px">
                      {getDisabledButtonText(hit.disabledStatus)}
                    </Button>
                  </Box>
                </>
              ) : null}
            </>
          ) : (
            <UpdateCartItemQuantity
              product={hit}
              quantity={clickedQuantity || 0}
              setQuantity={setClickedQuantity}
              maxQuantity={hit?.maxOrderQuantity}
              cartLocation={locationOfCart}
              viewingCategoryId={viewingCategoryId}
              searchQuery={searchQuery}
              hitItemIndex={hitItemIndex}
              restrictionModal={learnMoreAlcRestrictionModal}
              renderButton={
                !disabledProductBecauseOfRestriction ? (
                  <ATBButtonPopover
                    hit={transformHit(hit) as Hit}
                    displayPrice={displayPrice}
                    isPLPTile={isPLPTile}
                    onButtonClick={(e) => {
                      addToBasket(e)
                      sendAddToCartData(
                        hit,
                        locationOfCart,
                        viewingCategoryId,
                        searchQuery,
                        hitItemIndex
                      )
                    }}
                    width={['auto', '150px']}
                    alignSelf="flex-end"
                  />
                ) : undefined
              }
            />
          )}
        </Box>
      </Flex>
      {disabledProductBecauseOfRestriction ? (
        <LearnMoreAlcoholRestrictionModal {...learnMoreAlcRestrictionModal} />
      ) : null}
    </Box>
  )
}

export const HitItem = observer(HitItemContainer)

import React, {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {Wrapper} from '@googlemaps/react-wrapper'
import {flowResult} from 'mobx'
import {modalColour} from '../../pages/account/partials/account-payment-cards/partials/add-new-payment-card-modal/style.css'
import useDeliveryBooking from '../../hooks/use-delivery-booking'
import {
  useBasketStore,
  useCustomerStore,
  useGlobalStore,
  useOrderStore,
  useStoreDetailsStore,
} from '../../store/hooks/useStore'
import {BaseButton, Box, Button, Flex, Stack, Text} from '../../vanilla'
import {CheckPostcode} from '../book-delivery/check-postcode'
import {DeliveryAddress} from '../book-delivery/delivery-address'
import {DeliveryDateTime} from '../book-delivery/delivery-date-time'
import {FreeNextDay} from '../book-delivery/free-next-day'
import {Countdown} from '../book-delivery/reservation-status'
import {
  CheckIcon,
  ChevronLeftIcon,
  CloseIcon,
  DeliverySmallIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '../icons'
import {Link} from '../link'
import LoadingOverlay from '../loading-overlay'
import {baseThemeContract} from '../../vanilla/vars.css'
import useNavigation from '../../hooks/use-navigation'
import NearestStoreDetails from '../nearest-store-details'
import {DeliveryOrderInfo} from '../book-delivery/delivery-order-info'
import {useDeliveryReservationTime} from '../../hooks/use-delivery-reservation-time'
import {useBookDeliveryPopover} from '../../contexts'
import {BookDeliveryPopoverPlacementMap} from '../../types/ui'
import {ProductItem} from '../../store/BasketStore'
import {useSlotExpiration} from '../../store/hooks/useSlotExpiration'

export interface BookADeliveryProps {
  setIsOpen?: (val: boolean) => void
  product?: ProductItem
  search?: boolean
  onNavigate?: (url: string) => void
}

export const BookADelivery = observer(function BookADelivery({
  setIsOpen,
  product,
  search,
  onNavigate,
}: BookADeliveryProps) {
  const navigate = typeof onNavigate === 'function' ? onNavigate : useNavigation()
  const {googleMapsAPIkey} = useGlobalStore()

  const {
    deliveryPostalCode,
    basket,
    currentDeliverySlotId,
    currentDeliveryDay,
    isAppliedPostCodeDeliverable: isDeliverable,
    updateBasketItemQuantity,
  } = useBasketStore()
  const {isReservationExpired} = useSlotExpiration()

  const {isRegistered} = useCustomerStore()
  const {orderEditDeliverySlotChanged, editMode, recentOrder} = useOrderStore()

  const {clearPostalCode, loading, reserveSlot} = useDeliveryBooking()
  const {loading: storeDetailLoading} = useStoreDetailsStore()
  const [isReservationExpiredLocal, setisReservationExpiredLocal] = useState(isReservationExpired())
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false)
  const {isAvailable} = currentDeliveryDay

  const deliveryReservationDuration = useDeliveryReservationTime()

  useEffect(() => {
    if (search) {
      setShouldAutoFocus(true)
    }
  }, [search])

  useEffect(() => {
    setisReservationExpiredLocal(isReservationExpired())
  }, [isReservationExpired()])

  const addToBasket = async () => {
    if (product) {
      await flowResult(
        updateBasketItemQuantity({
          product,
          quantity: 1,
        }),
      )
    }
  }

  const handleContinueButton = async () => {
    addToBasket()
    setIsOpen?.(false)
  }

  const handlePrimaryButton = () => {
    if (isDeliverable) {
      addToBasket()
      navigate('/book-delivery', 'push')
    } else {
      navigate('/account/register', 'push')
    }
    setIsOpen?.(false)
  }

  const renderBookingAction = useCallback(() => {
    if (isReservationExpiredLocal) {
      if (!isRegistered && isAvailable) {
        return (
          <Button
            size="sm"
            variant="primary"
            style={{minWidth: '180px'}}
            onClick={() =>
              navigate('/login', 'push', {
                directedFrom: '/book-delivery',
              })
            }
          >
            <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
              Sign In to Rebook
            </Text>
          </Button>
        )
      }

      return (
        <BaseButton
          variant="primary"
          size="sm"
          style={{padding: '0 20px !important', minWidth: '180px'}}
          onClick={() => {
            isAvailable ? reserveSlot(currentDeliverySlotId) : navigate('/book-delivery')
            setIsOpen?.(false)}}
        >
          <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
            {isAvailable ? 'Rebook' : 'Book a New Delivery Time'}
          </Text>
        </BaseButton>
      )
    }

    return (
      <BaseButton
        variant="primary"
        size="sm"
        onClick={() => {setIsOpen?.(false)
          navigate('/book-delivery')}}
      >
        <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
          Change Delivery Date
        </Text>
      </BaseButton>
    )
  }, [isAvailable, isRegistered, isReservationExpiredLocal])

  return (
    <Stack
      backgroundColor="white"
      paddingX={currentDeliverySlotId ? '0px' : '20px'}
      paddingY="20px"
      style={{
        maxWidth: '100%',
        width: '450px',
        boxShadow: '0 10px 25px 0 rgb(0 0 0 / 40%)',
        overflow: 'hidden',
        margin: '0 auto',
      }}
      spacing="16px"
      position="relative"
      data-test-selector="book-delivery-popover"
    >
      <BaseButton
        variant="unstyled"
        height="auto"
        paddingX="0px"
        position="absolute"
        style={{top: '15px', right: '10px', zIndex: '1'}}
        onClick={() => setIsOpen?.(false)}
        aria-label="Close Book a Delivery popover"
      >
        <CloseIcon color="black" width="24px" height="24px" />
      </BaseButton>

      {currentDeliverySlotId ? (
        <>
          <Stack spacing="16px" borderBottom="1px" borderColor="gray200" paddingBottom="20px">
            {isReservationExpiredLocal ? (
              <Box paddingX="20px">
                <Flex align="center" gap="4px">
                  <ExclamationCircleIcon color="accent0" marginBottom="2px" />
                  <Text color="accent0" variant="text4">
                    Your delivery reservation has expired!
                  </Text>
                </Flex>
                <Text variant="text4" paddingLeft="20px">
                  {isAvailable
                    ? deliveryReservationDuration
                    : 'Your chosen time is no longer available:'}
                </Text>
              </Box>
            ) : null}
            <Box paddingX="20px">
              <DeliveryDateTime popover={true} />
            </Box>
            {!isReservationExpiredLocal && (
              <Box paddingX="20px">
                <DeliveryAddress />
              </Box>
            )}
          </Stack>

          <Flex paddingX="20px" justify="space-between" align="center">
            {!isReservationExpiredLocal && (!editMode || orderEditDeliverySlotChanged) && (
              <Text variant="text2" lineHeight="none">
                This delivery reservation expires in{' '}
                <Countdown
                  textVariant="text2"
                  color="accent0"
                  slotExpiration={basket?.c_slotExpiryTime}
                  onCountdownEnd={() => setisReservationExpiredLocal(true)}
                />
              </Text>
            )}
            {renderBookingAction()}
          </Flex>
        </>
      ) : (
        <Stack>
          {!recentOrder &&
          ((isDeliverable && basket?.c_deliveryPostalCode) ||
            typeof isDeliverable !== 'boolean' ||
            !basket?.c_deliveryPostalCode) ? (
            <Flex display={'block'} justify="space-between">
              <FreeNextDay />
            </Flex>
          ) : null}

          {recentOrder ? (
            <Flex direction="column" gap="16px">
              <DeliveryOrderInfo order={recentOrder} />
            </Flex>
          ) : basket?.c_deliveryPostalCode ? (
            <Stack spacing="16px" position="relative">
              <LoadingOverlay isLoading={loading || storeDetailLoading} />
              {isDeliverable ? (
                <Flex>
                  <CheckIcon color="accent1" width="48px" height="28px" />
                  <Text variant="text4" data-cs-mask="">
                    {`Great news! We'll deliver your shop to ${deliveryPostalCode} for free when you spend over £40!`}{' '}
                    <Text
                      as="span"
                      variant="text4"
                      padding="0px"
                      textDecoration="underline"
                      color="accent3"
                      lineHeight="short"
                      cursor="pointer"
                      onClick={clearPostalCode}
                    >
                      Change postcode
                    </Text>
                  </Text>
                </Flex>
              ) : (
                <Wrapper apiKey={googleMapsAPIkey}>
                  <Flex gap="40px">
                    <Button
                      variant="unstyled"
                      alignItems="center"
                      marginTop="2px"
                      gap="0px"
                      padding="0px"
                      paddingLeft="0px"
                      paddingRight="0px"
                      bg="white"
                      height="auto"
                      onClick={() => clearPostalCode()}
                      iconLeft={<ChevronLeftIcon style={{width: 10}} color="gray800" />}
                    >
                      Back
                    </Button>
                    <Text variant="text4" flexGrow="1" data-cs-mask="">
                      {`Sorry, we don't deliver to ${deliveryPostalCode} at the moment. Create an account with us and we will let you know when online deliveries are available in your area.`}{' '}
                      <Text
                        as="span"
                        variant="text4"
                        padding="0px"
                        textDecoration="underline"
                        color="accent3"
                        lineHeight="short"
                        cursor="pointer"
                        onClick={clearPostalCode}
                      >
                        Change postcode
                      </Text>
                    </Text>
                  </Flex>
                  <NearestStoreDetails
                    wrapperStyles={{
                      borderBottom: '1px',
                      borderTop: '1px',
                      borderColor: '#f3f3f3',
                      paddingTop: '20px',
                      borderTopStyle: 'solid',
                      borderBottomStyle: 'solid',
                      marginBottom: '10px',
                    }}
                    viewText="View on map"
                  />
                </Wrapper>
              )}
              <Stack direction="row">
                <Button
                  size="sm"
                  variant="outlineDark"
                  onClick={handleContinueButton}
                  style={{width: '180px'}}
                >
                  {`Continue${!isDeliverable ? ' Browsing' : ''}`}
                </Button>
                {(isDeliverable || !isRegistered) && (
                  <BaseButton
                    onClick={handlePrimaryButton}
                    variant="primary"
                    style={{width: '180px'}}
                    size="sm"
                  >
                    <Text
                      variant="unstyled"
                      fontSize="inherit"
                      lineHeight="inherit"
                      fontWeight="inherit"
                    >
                      {isDeliverable ? 'Book Delivery' : 'Create an Account'}
                    </Text>
                  </BaseButton>
                )}
              </Stack>
            </Stack>
          ) : (
            <>
              <Text variant="text4">
                <Stack
                  display={search ? ['block', 'inline-block'] : 'inline-block'}
                  className={search ? modalColour : undefined}
                >
                  Check if we can deliver.
                </Stack>
                Please enter your postcode below to check if we are able to deliver to your address.
              </Text>
              <CheckPostcode autoFocus={shouldAutoFocus} hideText />
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
})

export const BookADeliveryPopover = observer(() => {
  const {basket, currentDeliverySlotId, deliveryDate, slotTime} =
    useBasketStore()
  const {isReservationExpired} = useSlotExpiration()

  const deliveryWindow = `${slotTime(basket?.c_windowStartTime)} - ${slotTime(
    basket?.c_windowEndTime,
  )}`

  const {PopoverWrapperComponent, toggleBookDeliveryPopover, hideBookDeliveryPopover, isOpen, clickedHitId} =
    useBookDeliveryPopover()

  const customerStore = useCustomerStore()

  const [ignoreClickOutside, setIgnoreClickOutside] = useState(false)

  const handleClickOutside = (event: MouseEvent) => {
    if (!ignoreClickOutside) {
      hideBookDeliveryPopover()
    } else {
      setIgnoreClickOutside(false)
    }
  }

  const handleToggle = () => {
    toggleBookDeliveryPopover({ placement: BookDeliveryPopoverPlacementMap.ATTACHED })
    customerStore.setLastActiveToNow()

    if (!isOpen) {
      setIgnoreClickOutside(true)
    }
  }
  return (
    <PopoverWrapperComponent
      positions={['bottom']}
      align="end"
      containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
      reposition={true}
      isOpen={isOpen && !clickedHitId}
      onClickOutside={handleClickOutside}
    >
      <Button
        marginRight="8px"
        variant="outlineLight"
        data-test-selector="book-delivery-button"
        onClick={handleToggle}
        position="relative"
      >
        {isReservationExpired() && (
          <ExclamationTriangleIcon
            color="accent0"
            position="absolute"
            height="20px"
            width="20px"
            style={{top: '-10px', left: '-10px'}}
            zIndex="docked"
          />
        )}
        <Flex paddingY="8px" gap="8px">
          <DeliverySmallIcon color="accent3" height="24px" width="auto" />
          {currentDeliverySlotId ? (
            <Box>
              <Text variant="unstyled" textAlign="right" fontSize="xxs" lineHeight="none">
                {deliveryDate}
              </Text>
              <Text variant="unstyled" textAlign="right" fontSize="xs" lineHeight="none">
                {deliveryWindow}
              </Text>
            </Box>
          ) : (
            <Text variant="text3" textDecoration="none">
              Book a Delivery
            </Text>
          )}
        </Flex>
      </Button>
    </PopoverWrapperComponent>
  )
})